import { DateTime, Duration } from "luxon";

const data = {
    fixedPrice: null,
    packagePrice: null,

    scheduledFor: null,
    scheduledUntil: null,
    startsAt: null,
    endsAt: null,
    distance: null,

    pricePerHour: null,
    pricePerKm: null,

    serviceValue: null,
    service: {
        "x-model.fill": "serviceValue",
        "x-ref": "serviceElement",
    },

    init() {
        this.$watch("serviceValue", () => {
            const option = this.$refs.serviceElement.selectedOptions[0];

            this.pricePerHour = +(option.dataset.priceHour ?? 0);
            this.pricePerKm = +(option.dataset.priceKm ?? 0);
        });
    },

    get totalDays() {
        if (!this.scheduledUntil) {
            return this.scheduledFor ? 1 : 0;
        }

        const start = new Date(this.scheduledFor);
        const end = new Date(this.scheduledUntil);

        const diff = end - start;
        const days = diff / (1000 * 60 * 60 * 24);

        return days + 1;
    },

    get hoursPerDay() {
        if (!this.startsAt || !this.endsAt) {
            return Duration.fromObject({ hours: 0 });
        }

        const start = DateTime.fromISO(this.startsAt);
        let end = DateTime.fromISO(this.endsAt);

        if (start > end) {
            end = end.plus({ days: 1 });
        }

        return end.diff(start, ["hours"]);
    },

    get totalHours() {
        return this.hoursPerDay.mapUnits((value) => this.totalDays * value);
    },

    get timePrice() {
        return this.pricePerHour * this.totalHours.as("hours");
    },

    get distancePrice() {
        return this.pricePerKm * this.distance;
    },

    get totalPrice() {
        if (this.fixedPrice) return this.packagePriceAsMoney;

        return this.timePrice + this.distancePrice;
    },

    get packagePriceAsMoney() {
        const value = this.packagePrice || "0";

        return value.replace(/\D/g, "") / 100;
    },
};

export default data;
