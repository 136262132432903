import useSelect from "../../composables/useSelect";

const createJob = {
    jobList: null,
    directorList: null,

    producer: null,
    address: null,
    job_name: null,
    uuid: null,
    startDate: null,
    endDate: null,
    price_list: null,

    init() {
        this.directorList = useSelect("#director", {
            create: true,
            createOnBlur: true,
            selectOnTab: true,
            closeAfterSelect: true,
            remoteURI: query => `/producer/name/${query}`
        })

        this.jobList = useSelect("#job_name", {
            create: true,
            createOnBlur: true,
            selectOnTab: true,
            preload: true,
            maxItems: 1,
            searchField: 'value',
            labelField: 'value',
            remoteURI: () => '/job/name_list'
        })

        this.jobList.on('load', () => {
            this.$watch('job_name', this.watchJobName.bind(this))
        })

        this.$watch('producer', id => {
            if (Number(id) < 1) return;

            this.address = document
                .querySelector(`option[value='${this.producer}']`)
                ?.dataset
                ?.address
        })

    },
    watchJobName(name) {
        const job = this.jobList.options[name]

        if (!job?.uuid) return

        this.directorList.clear()

        this.uuid = job.uuid
        this.producer = job.producer_id
        this.price_list = job.price_list_id

        job.director?.split(',').forEach(name => {
            this.directorList.createItem(name)
            this.directorList.addItem(name)
        })
    }
}

export default createJob
