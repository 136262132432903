import Alpine from "alpinejs";
import { Duration } from "luxon";

const formatters = {
    day: (value) =>
        new Intl.NumberFormat("pt-BR", { style: "unit", unit: "day" }).format(
            value,
        ),
    distance: (value) =>
        new Intl.NumberFormat("pt-BR", {
            style: "unit",
            unit: "kilometer",
        }).format(value),
    duration: (value) => {
        value = value.shiftTo('hours', 'minutes')
        return new Intl.DurationFormat("pt-BR", { style: "long" }).format(value)
    },
    hour: (value) =>
        new Intl.NumberFormat("pt-BR", {
            style: "unit",
            unit: "hour",
            unitDisplay: "long",
        }).format(value),
    money: (value) =>
        new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(value),
};

Alpine.directive(
    "format",
    (el, { expression, modifiers }, { effect, evaluateLater }) => {
        let evaluate = evaluateLater(expression);

        effect(() => {
            evaluate((value) => {
                Alpine.mutateDom(() => {
                    const [modifier] = modifiers;

                    if (formatters[modifier]) {
                        value = formatters[modifier](value);
                    }

                    el.textContent = value;
                });
            });
        });
    },
);
