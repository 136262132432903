const formatter = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const data = {
    placeholder: "0,00",

    "@input"() {
        const value = this.$el.value.replace(/\D/g, "");
        const formatted = formatter.format(value / 100);

        if (this.$el.value != formatted) {
            this.$el.value = formatted;
        }
    },
};

export default data;
